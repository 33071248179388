.option {
  @apply text-xs sm:text-sm font-medium text-white hover:underline text-left;
}

.sectionHeader {
  @apply text-white font-semibold mb-3;
}

.socialIcon {
  @apply w-6 h-6 mr-4;
}