.listContainer {
  @apply grid gap-4 mt-4 sm:mt-8;
  @apply grid-cols-2 sm:grid-cols-3 lg:grid-cols-4;
}

.bookmarkItemContainer {
  @apply bg-GRAY_BG rounded-md p-4 mb-4 flex flex-col justify-between truncate;
}

.bookmarkItemImage {
  @apply w-full h-[130px] rounded-md;
}
