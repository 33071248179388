.listContainer {
  @apply grid gap-4 mt-4 sm:mt-8;
  @apply grid-cols-1 sm:grid-cols-2 lg:grid-cols-3;
}

.centerContainer {
  @apply mt-20 flex justify-center items-center;
}

.pageContainer {
  @apply mx-auto xl:mx-0 max-w-[768px];
}

.formFieldsContainer {
  @apply grid gap-4 sm:gap-8 mt-4 sm:mt-8;
  @apply grid-cols-1 sm:grid-cols-2;
}

.otherDetailsHeader {
  @apply flex items-center justify-between mt-8;
}

.otherDetailsHeader h5 {
  @apply font-semibold;
}

.iconContainer {
  @apply border-2 border-black w-8 h-8 rounded-full flex justify-center items-center;
}

.imagesContainer {
  @apply mt-8 flex flex-nowrap overflow-x-auto sm:justify-center;
  -webkit-overflow-scrolling: touch;
}

.addImageContainer {
  @apply w-32 h-32 rounded-lg bg-GRAY_BG m-4 flex flex-col justify-center items-center hover:drop-shadow-lg;
}

.addImage {
  @apply w-full h-full rounded-lg;
}

.defaultImage {
  @apply w-12 h-12;
}

.imagePlusContainer {
  @apply bg-white w-5 h-5 rounded-full flex justify-center items-center;
}

.checkBoxButton {
  @apply border border-black rounded p-1 mr-2;
}

.checkBoxCircle {
  @apply w-5 h-5 rounded-full;
}

.propertyItemContainer {
  @apply bg-GRAY_BG p-3 rounded-lg flex flex-col justify-between;
}

.propertyItemContainer h5 {
  @apply text-base font-bold;
}

.propertyItemContainer h6 {
  @apply text-sm font-semibold;
}

.propertyImage {
  @apply w-full h-[220px] bg-white rounded-lg flex items-center justify-center object-cover;
}
