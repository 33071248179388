.pageContainer {
  @apply mx-auto px-4 lg:px-16 grid grid-cols-1 md:grid-cols-2 gap-4 pt-8 pb-8 overflow-hidden;
  min-height: calc(100vh - var(--header-height));
}

.bgImg {
  @apply absolute bottom-0 sm:bottom-[-40px] overflow-hidden w-[100vw] z-[-1];
}

.titleContainer {
  @apply h-auto;
}

.inputContainer {
  @apply flex flex-col h-full items-center text-xs sm:text-sm;
}

.inputCard {
  @apply bg-white shadow-md rounded-xl p-4 sm:p-8 sm:m-4 flex items-center justify-center;
}

.inputCardContent {
  @apply flex flex-col justify-center space-y-4;
}

.phoneInput {
  @apply flex items-center space-x-2 w-full border-b;
}

.phoneInputField {
  @apply flex-grow p-2 border-gray-200 focus:outline-none;
}

.termsText {
  @apply text-xs leading-snug text-center whitespace-pre-line px-1;
}

.emailLink {
  @apply underline;
}

.otpContainer {
  @apply flex items-center w-full justify-center;
}

.otpInput {
  @apply w-10 h-10 border-2 m-2 p-1 text-center rounded;
}

.signUpbgImg {
  @apply absolute left-0 top-0 overflow-hidden w-[100vw] z-[-1];
}

.formInputContainer {
  @apply border-SECONDARY rounded-md py-2 px-2 flex items-center w-full;
}

.formLabel {
  @apply text-gray-600 text-xs w-16;
}

.formInput {
  @apply bg-transparent focus:outline-none;
}

.verifyLink {
  @apply text-xs underline w-10;
}
