.profilePage {
  @apply max-w-[768px] mx-auto;
}

.mainOptionContainer {
  @apply grid gap-4 mt-4 sm:mt-8;
  @apply grid-cols-2;
}

.mainOption {
  @apply bg-PRIMARY hover:bg-black p-4 rounded-2xl flex flex-col items-center justify-center;
}

.mainOption p {
  @apply text-xs sm:text-sm text-white pt-4 font-medium;
}

.mainOptionIcon {
  @apply w-6 lg:w-10 h-6 lg:h-10;
}

.mainOptionIcon path {
  stroke: white;
}

.secondaryOption {
  @apply flex w-full items-center p-6 shadow rounded-2xl mb-1 hover:shadow-xl;
}

.secondaryIcon {
  @apply w-4 lg:w-5 h-4 lg:h-5 mr-5;
}

.arrowIcon {
  @apply w-4 lg:w-5 h-4 lg:h-5 ml-auto;
}

.formFieldsContainer {
  @apply mt-2 grid grid-cols-2 gap-2;
}
