.pageLayout {
  @apply xs:w-full lg:w-[720px] xl:w-[768px] 2xl:w-[920px] px-4 mx-auto;
}
/* --- */
.pageContainer {
  @apply flex flex-col;
}

.centerContainer {
  @apply flex-grow flex flex-col min-h-screen items-center justify-center px-2;
}

.heroContainer {
  @apply flex flex-col items-center relative;
}

.heroImage {
  @apply min-h-[240px] lg:w-[80vw] mx-auto align-top;
  border-radius: 0px 0px 40px 40px;
}

.heroContainer::before {
  @apply w-full; /* Use full width for mobile and adjust as needed for larger screens */
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.3); /* Applied to all screen sizes */
  border-radius: 0px 0px 40px 40px;

  @apply lg:w-[80vw]; /* Only for large screens */
}

.heroContent {
  @apply absolute pageLayout bottom-2 md:bottom-[30px] lg:bottom-[40px] xl:bottom-1/4 mx-auto z-10;
}

.heroTitle {
  @apply mb-4 text-white text-xl md:text-2xl lg:text-3xl font-bold leading-tight tracking-tight drop-shadow-xl;
}

.citiesContainer {
  @apply flex bg-black bg-opacity-60 rounded-t-xl sm:h-16 sm:justify-around items-start px-2 md:px-8 overflow-x-auto;
}

.cityOption {
  @apply text-white opacity-50 px-2 py-2 transition-opacity duration-300 ease-in-out whitespace-nowrap;
}

.cityOption:hover,
.selectedCityOption {
  @apply opacity-100 underline;
}

.searchContainer {
  @apply bg-white shadow rounded-b-xl border-t sm:border-none sm:rounded-3xl flex items-center justify-between z-50 sm:bottom-6 relative;
}

.searchButton {
  @apply hidden sm:block rounded-3xl m-1;
}

.searchInput {
  @apply flex-grow bg-transparent focus:outline-none p-2 text-sm italic;
}

.separator {
  @apply hidden sm:block border-r border-black h-11;
}

.selectContainer {
  @apply hidden sm:flex items-center justify-center p-2 w-40 z-50;
}

.xsSelectContainer {
  @apply sm:hidden rounded-none;
}

.popLocContainer {
  @apply hidden sm:flex justify-center items-center px-2 mt-4;
}

.popLocContainer > p {
  @apply font-semibold text-sm whitespace-nowrap text-white;
}

.popLocContainer > div {
  @apply flex flex-wrap gap-2 mx-2;
}

.contentContainer {
  @apply pageLayout mx-auto relative mt-2 sm:mt-0 sm:-top-6 xl:-top-16;
}

.spotlightsContainer {
  @apply mx-auto flex justify-around;
}

.spotlightsItem {
  @apply w-48 flex flex-col items-center;
}

.spotlightsItem > p {
  @apply font-semibold text-xs sm:text-lg py-1 capitalize;
}

.spotlightIconContainer {
  @apply bg-black w-14 h-14 sm:w-24 sm:h-24 xl:w-32 xl:h-32 rounded-full flex items-center justify-center;
}

.spotlightIcon {
  @apply w-5 sm:w-20;
}

.summaryContainer {
  @apply flex justify-around;
}

.summaryItem {
  @apply box-border w-1/2 h-40 mr-2 sm:h-52 sm:w-80 lg:w-72 border border-black rounded-3xl flex flex-col justify-around items-center;
}

.summaryItem > h2 {
  @apply text-3xl;
}

.summaryItem > p {
  @apply text-sm font-semibold;
}

.summaryCount {
  @apply text-4xl font-bold;
}

.sectionContainer {
  @apply mt-10 ;
}

.sectionTitleContainer {
  @apply flex justify-between w-full pb-2;
}

.sectionTitleContainer h6 {
  @apply text-lg font-semibold capitalize;
}

.sectionTitleContainer p {
  @apply text-xs font-thin;
}

.sectionTitleContainer > button {
  @apply text-sm font-light italic underline;
}

.sectionItemsContainer {
  @apply flex overflow-x-auto py-2 px-2 justify-start;
}

.noticeImgContainer {
  @apply inline-flex flex-none justify-center items-center h-28 w-28 sm:h-40 sm:w-40 m-2 border border-black rounded-3xl;
}

.noticeImgStyles {
  @apply w-full h-full object-cover rounded-3xl;
}

.propBannerContainer {
  @apply mt-10 relative max-w-2xl mx-auto;
}

.propBannerImg {
  @apply w-full;
}

.propBannerContent {
  @apply absolute top-0 p-4 max-w-[70%] h-full flex flex-col justify-around;
}

.propBannerContent > p {
  @apply text-white mb-4 font-medium text-xs sm:text-lg md:text-xl lg:text-2xl;
}

.propBannerButton {
  @apply max-w-[90%] sm:max-w-[60%] p-1 sm:p-4;
}

.alertIcon {
  @apply mr-2 w-10;
}

.newsItemContainer {
  @apply inline-flex flex-none flex-col items-start w-28 sm:w-40 m-2 text-black;
}

.newsItemImg {
  @apply w-full h-28 sm:h-40 rounded-3xl;
}

.newsItemContainer h6 {
  @apply text-xs font-semibold py-2;
}

.newsItemContainer p {
  @apply text-[8px] font-thin text-black;
}

.onbCityOption {
  @apply mt-4 mb-8 relative max-w-[200px] flex flex-col items-center;
}

.onbCityOption img {
  @apply w-20 h-20 rounded-full my-2 mx-4;
}

.onbCityOption p {
  @apply text-sm font-medium;
}

.onbImageMask {
  @apply absolute my-2 mx-4 top-0 w-20 h-20 bg-gray-50 opacity-50 rounded-full;
}

.onbNoticeTypeContainer {
  @apply my-4;
}

.onbNoticeTypeOption {
  @apply h-10 rounded-full border border-black mr-2 mb-2 px-4;
}

.onbNoticeTypeOption.selected {
  @apply h-10 rounded-full bg-PRIMARY px-4 border-PRIMARY;
}

.onbNoticeTypeOption p {
  @apply text-sm font-medium;
}

.listScreenContainer {
  @apply mx-auto mt-4 lg:mt-16 px-4;
}

.tn_citiesContainer {
  @apply flex;
}

.noticeImagesContainer {
  @apply grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 mt-8;
}

.noticeImage {
  @apply h-40 w-40 m-4;
}