.header {
  @apply mx-auto flex items-center px-4 py-6 space-y-4 xl:space-y-0 xl:flex xl:items-center xl:space-x-10 relative;
  @apply max-w-2xl lg:max-w-5xl xl:max-w-6xl 2xl:max-w-7xl;
  height: var(--header-height);
}

.authHeader {
  @apply mx-auto flex items-center py-6 space-y-4 xl:space-y-0 xl:flex xl:items-center xl:space-x-10 relative;
  @apply px-16;
}

.logoButton {
  @apply flex items-center;
}

.authLogoButton {
  @apply flex items-center absolute left-0 lg:left-12;
}

.navContainer {
  @apply flex items-center w-full justify-between;
}

.title {
  @apply text-lg font-bold;
}

nav button {
  @apply mx-2;
}

nav button p {
  @apply text-white px-2 whitespace-nowrap;
}

nav button:hover p {
  @apply font-semibold;
  letter-spacing: -0.011em;
}

.mobileMenu {
  @apply flex flex-col px-4 py-4;
}

.mobileMenu button {
  @apply py-2;
}

.mobileMenu button p {
  @apply font-semibold
}

.pendingAmountContainer {
  @apply flex items-center; /* This makes it a flex container */
}

.dollarIcon {
  /* Remove absolute positioning and adjust the margin to pull icon towards the text container */
  @apply mr-[-10px] pb-[2px];
}

.pendingAmountTxtContainer {
  @apply border border-GOLD rounded pl-[10px] pr-1 py-1; /* Adjust left padding to accommodate icon */
}

.pendingAmountTxtContainer p {
  @apply text-xs font-medium text-GOLD text-right p-0 m-0; /* Ensuring no margin interferes */
}
