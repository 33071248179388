.pageContainer {
  @apply mt-5;
}

.listContainer {
  @apply grid gap-4 mt-4 sm:mt-8;
  @apply grid-cols-1 sm:grid-cols-2 lg:grid-cols-3;
}

.noticeItemContainer {
  @apply bg-GRAY_BG rounded-md p-2 mb-4 flex flex-row items-center;
}

.noticeItemImage {
  @apply w-[109px] h-[159px] rounded-md object-cover p-1;
}

.noticeItemInfoContainer {
  @apply pt-[5px] pl-2 pb-2 text-left w-[3/5] truncate;
}

.noticeItemInfoContainer p {
  @apply text-black font-normal truncate;
}

.noticeItemInfoTitleContainer {
  @apply border-b border-black flex justify-between mb-2 items-center;
}

.noticeItemInfoTitleContainer h5 {
  @apply font-bold pb-2;
}

.noticeItemInfoTitleContainer h6 {
  @apply font-bold pb-2 text-xs;
}

.centerContainer {
  @apply w-full flex items-center justify-center;
}

.detailsButton {
  @apply mt-2 p-1 w-full max-w-[200px];
}

.imageContainer {
  @apply h-[488px] flex flex-col items-center justify-between relative;
}

.noticeImage {
  @apply absolute inset-0 w-full max-h-[428px] max-w-[350px] rounded-[10px] mx-auto;
}

.hiddenImageOverlay {
  @apply noticeImage bg-PRIMARY bg-opacity-30 z-10;
}

.noticeDetailsContainer {
  @apply bg-GRAY_BG rounded-2xl mb-2 p-4 mx-auto truncate;
}

.noticeDetailsContainer p {
  @apply text-black text-xs p-1;
}

.locationDetailsContainer {
  @apply bg-PRIMARY rounded-2xl p-4 truncate;
}

.locationDetailsContainer p {
  @apply text-white text-xs p-1;
}

.separator {
  @apply absolute h-full w-px bg-black left-[48%];
}

.myNoticeItemContainer {
  @apply m-2 w-[calc(50%-1rem)] sm:w-[calc(33.333%-1rem)] lg:w-[calc(25%-1rem)] bg-GRAY_BG rounded-2xl p-4 flex flex-col items-center;
}

.myNoticeImage {
  @apply w-[135px] h-[170px] object-cover mx-auto mt-2 mb-4;
}

.purchaseButton {
  @apply w-full bg-transparent text-black mt-2 flex items-center hover:text-white;

  /* Add hover effect to change SVG fill color */
  &:hover svg {
    fill: white;
  }
}

.actionBar button:hover p {
  @apply font-semibold;
}
