.titleContainer h6 {
  @apply font-semibold;
}

.titleContainer {
  @apply flex justify-between items-center mb-8;
}

.advFilterContainer {
  @apply flex h-[500px] relative;
}

.advFilterOptions {
  @apply w-1/4 fixed;
}

.bottomContainer {
  @apply p-2 mb-2 absolute bottom-0 flex w-full justify-between items-center;
}

.advFilterValuesContainer {
  @apply ml-auto w-3/4 p-5;
}

.filterOptionContainer {
  @apply p-4 w-full text-black text-xs text-left font-medium;
}

.filterOptionContainer.selected {
  @apply text-white bg-PRIMARY;
}

.filterOptionContainer.disabled {
  @apply text-gray-500 pointer-events-none;
}

.filterOptionContainer:hover {
  @apply text-white bg-PRIMARY;
}

.valueTag {
  @apply bg-GRAY_BG px-4 py-1 text-black text-sm font-normal rounded-xl mr-2 mb-2;
}

.valueTag.selected {
  @apply bg-PRIMARY text-white;
}

.optionHeader {
  @apply flex items-center justify-between pb-4;
}

.optionHeader h6 {
  @apply font-semibold;
}

.pillTagContainer {
  @apply flex flex-wrap p-2 items-center;
}

.pillTagContainer .valueTag {
  @apply bg-SECONDARY text-white;
}

.clearButton:hover {
  & p {
    @apply text-white;
  }

  & svg {
    @apply fill-white;
  }
}
