.receiptDetailsContainer {
  @apply border-y border-black py-4 w-full flex flex-wrap justify-between mb-4;
}

.receiptDetailBox {
  @apply bg-GRAY_BG rounded-xl w-[49%] mb-2 p-2;
}

.listContainer {
  @apply grid gap-4 mt-4 sm:mt-8;
  @apply grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4;
}

.transactionItemContainer {
  @apply bg-GRAY_BG rounded-md p-4 mb-4 flex flex-col;
}

.balanceCreditsContainer {
  @apply bg-PURPLE rounded-xl items-center p-4 mt-4 mb-4 sm:mb-8;
  @apply grid gap-2 grid-cols-1 sm:grid-cols-2 lg:grid-cols-3;
}

.balanceCreditsContainer p {
  @apply font-semibold text-sm sm:text-xl mt-2 ml-2;
}
