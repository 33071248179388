.listContainer {
  @apply grid gap-4 mt-4 sm:mt-8;
  @apply grid-cols-1 sm:grid-cols-2;
}

.alertItemContainer {
  @apply bg-GRAY_BG rounded-2xl flex items-center p-2 sm:p-3 lg:p-4;
}

.alertImage {
  @apply w-[1/3];
}

.alertInfoHeader {
  @apply flex justify-between border-b border-black mb-2;
}

.centerContainer {
  @apply flex items-center justify-center w-full mt-4 mx-auto;
}
