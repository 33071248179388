.alertBannerContainer {
  @apply my-8 mx-auto p-4 rounded-3xl flex justify-around items-center max-w-2xl;
}
.alertBannerText {
  @apply mr-2 max-w-[70%];
}

.alertBannerText h5 {
  @apply text-white font-bold text-xl sm:text-2xl;
}

.alertBannerText p {
  @apply text-white text-xs sm:text-sm;
}

.alertIcon {
  @apply w-5 h-5 sm:w-10 sm:h-10 mr-2;
}
